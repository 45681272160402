@font-face {
  font-family: "freePixel";
  src: url("./Fonts/OpenMine.ttf");
}

body {
  margin: 0;
  font-family: "freePixel";
  overflow-x: hidden;
  background-image: url("./Images/Sky.png");
}

@media only screen and (max-width: 1200px) {
  body {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 900px) {
  .mountainWrapper1 {
    position: absolute;
    top: 700em;
  }
  .mountainWrapper2 {
    position: absolute;
    top: 700em;
  }
  .mountainWrapper3 {
    position: absolute;
    top: 700em;
  }
  .mountainWrapper4 {
    position: absolute;
    top: 700em;
  }
}

.mountains {
  position: absolute;
  bottom: 0;
  margin: 0;
}

.mountainWrapper1 {
  position: absolute;
  top: 600em;
}
.mountainWrapper2 {
  position: absolute;
  top: 600em;
}
.mountainWrapper3 {
  position: absolute;
  top: 600em;
}
.mountainWrapper4 {
  position: absolute;
  top: 600em;
}

.mountainsImages {
  height: 100%;
}

.mountains1 {
  background-image: url("./Images/Mountains-1.png");
  width: 100vw;
  height: 500px;
}
.mountains2 {
  background-image: url("./Images/Mountains-2.png");
  width: 100vw;
  height: 500px;
}
.mountains3 {
  background-image: url("./Images/Mountains-3.png");
  width: 100vw;
  height: 500px;
}
.mountains4 {
  background-image: url("./Images/Mountains-4.png");
  width: 100vw;
  height: 500px;
}

/*
*/
.clouds {
  position: absolute;
  top: 0;
  margin: 0;
}

.cloudWrapper1 {
  position: absolute;
  top: 0;
}
.cloudWrapper2 {
  position: absolute;
  top: 0;
}
.cloudWrapper3 {
  position: absolute;
  top: 0;
}

.cloudsImages {
  height: 100%;
}

.clouds1 {
  background-image: url("./Images/Clouds-1.png");
  width: 100vw;
  height: 500px;
}
.clouds2 {
  background-image: url("./Images/Clouds-2.png");
  width: 100vw;
  height: 500px;
}
.clouds3 {
  background-image: url("./Images/Clouds-3.png");
  width: 100vw;
  height: 500px;
}
